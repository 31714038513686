export const siteEmails = {
  sales: {
    address: 'rt@matrixdigital.com',
    label: 'rt@matrixdigital.com',
  },
}

export const socialPaths = {
  instagram: 'https://www.instagram.com/rtorcato/',
  facebook: '',
  github: 'https://github.com/rtorcato',
  codepen: '',
  linkedin: 'http://ca.linkedin.com/in/rtorcato/',
  stackoverflow: 'https://stackexchange.com/users/1254115/richard-torcato',
  twitter: 'https://twitter.com/rtorcato',
  codeSandBox: 'https://codesandbox.io/u/rtorcato',
}

export const sitePaths = {
  errors: {
    error404: '404/',
  },
  appLinks: {
    home: '/',
    products: '/products',
    about: '/about',
    contact: '/contact',
    portfolio: '/portfolio',
    portfolioProjects: '/portfolio/project/:id',
  },
  externalLinks: {
    matrixdigital: 'https://www.matrixdigital.com',
    tripstreamer: 'https://www.tripstreamer.com',
  },
}
