export const isWebBrowser = (): boolean => typeof window !== `undefined`

export const sleep = (ms: number): Promise<unknown> => {
  const promise = new Promise((resolve) => setTimeout(resolve, ms))
  return promise
}
export const asyncPrint = async (): Promise<void> => {
  await sleep(10000)
  window.print()
}

export const shuffle = <t>(array: t[]): t[] => {
  let currentIndex = array.length
  let temporaryValue: t
  let randomIndex = 0

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}
