import getYear from 'date-fns/getYear'
const year = getYear(new Date())
const favIconPath = '/ico'
const title = 'Matrix Digital Solutions'
const titleShort = 'Matrix Digital'
const url = 'https://www.matrixdigital.com'
const description = 'Simple Design, Advanced Technology'
const themeColor = '#c62828'
const maskColor = '#5bbad5'
const facebookAppID = '' // '1825356251115265', // FB Application ID for using app insights
const googleAnalyticsID = '' // 'UA-47311644-5', // GA tracking ID.
const twitterCreator = '@rtorcato'
const twitterImageAlt = 'Richard Torcato'
const keywords = ['Windows', 'Aluminum Windows', 'Ontario', 'Aluminum', 'construction']
const author = 'rtorcato@me.com'
const email = 'rt@matrixdigital.com'
const copyright = `Copyright &copy; ${year}. Matrix Digital Solutions Inc.`
const postDefaultCategoryID = 'Tech' // Default category for posts.
const dateFromFormat = 'YYYY-MM-DD' // Date format used in the frontmatter.
const dateFormat = 'DD/MM/YYYY' // Date format for display.
const postsPerPage = 4 // Amount of posts displayed per listing page.
const lastUpdated = 'April 21, 2020'
const phone = '14162303960'
const phoneDisplay = '1 (416) 230-3960'
const zIndex = {
  mainNav: 1050,
  mobileMenu: 1049,
  appOverlay: 1001,
  navBarOverlay: 1002,
  navSubMenuItem: 1003,
}
const colors = {
  primaryColor: '#258CC4',
}

export const siteConfig = {
  title,
  titleShort,
  url,
  favIconPath,
  description,
  googleAnalyticsID,
  facebookAppID,
  keywords,
  copyright,
  author,
  postDefaultCategoryID,
  dateFromFormat,
  dateFormat,
  postsPerPage,
  lastUpdated,
  phone,
  phoneDisplay,
  zIndex,
  email,
  colors,
  year,
}

export const metaTagData = {
  themeColor,
  favIconPath,
  siteFBAppID: '',
  siteUrl: '',
  title: title,
  description: description,
  keywords: keywords,
  lang: 'en',
  siteImage: `${favIconPath}/og-image.jpg`,
  facebook: {
    appID: facebookAppID,
  },
  browserConfig: `${favIconPath}/browserconfig.xml`,
  manifest: `${favIconPath}/manifest`,
  maskIcon: `${favIconPath}/safari-pinned-tab.svg`,
  maskColor,
  shortcutIcon: `${favIconPath}/favicon.ico`,
  twitter: {
    creator: twitterCreator,
    image: `${favIconPath}/og-image.jpg`,
    imageAlt: twitterImageAlt,
    site: twitterCreator,
  },
  icons: {
    appleTouch: `${favIconPath}/apple-touch-icon.png`,
    icon32: `${favIconPath}/favicon-32x32.png`,
    icon16: `${favIconPath}/favicon-16x16.png`,
  },
}

export default siteConfig
