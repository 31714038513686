import Helmet from 'react-helmet'
import React from 'react'
interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaTagData: any
}

const Head = ({ metaTagData }: IProps): JSX.Element => {
  return (
    <Helmet htmlAttributes={{ lang: metaTagData.lang }}>
      {/* General tags */}
      <title>{metaTagData.title}</title>
      <meta charSet="utf-8" />
      <meta name="keywords" content={metaTagData.keywords.join(',')} />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" content={metaTagData.description} />
      <meta name="image" content={metaTagData.siteImage} />
      {/* Facebook Open Graph Card tags */}
      {metaTagData.siteFBAppID.length > 0 && <meta property="fb:app_id" content={metaTagData.facebook.appID} />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTagData.title} />
      <meta property="og:description" content={metaTagData.description} />
      <meta property="og:image:width" content="1050" />
      <meta property="og:image:height" content="550" />
      <meta property="og:url" content={metaTagData.siteUrl} />
      <meta property="og:image" content={metaTagData.siteImage} />
      <meta property="og:site_name" content={metaTagData.title}></meta>
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={metaTagData.twitter.creator} />
      <meta name="twitter:title" content={metaTagData.title} />
      <meta name="twitter:description" content={metaTagData.description} />
      <meta name="twitter:image" content={metaTagData.twitter.image} />
      <meta name="twitter:image:alt" content={metaTagData.twitter.imageAlt}></meta>
      <meta name="twitter:site" content={metaTagData.twitter.site}></meta>
      {/* Site Icons */}
      <link rel="apple-touch-icon" sizes="180x180" href={metaTagData.icons.appleTouch} />
      <link rel="icon" type="image/png" sizes="32x32" href={metaTagData.icons.icon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={metaTagData.icons.icon16} />
      <link rel="manifest" href={metaTagData.manifest} />
      <link rel="mask-icon" href={metaTagData.maskIcon} color={metaTagData.maskColor} />
      <link rel="shortcut icon" href={metaTagData.shortcutIcon} />
      <meta name="msapplication-config" content={metaTagData.browserConfig} />
      <meta name="theme-color" content={metaTagData.themeColor} />
      {/* Stylesheets */}
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
        integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
        crossOrigin=""
      />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css"></link>
      {/*
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-social/4.12.0/bootstrap-social.min.css"></link>*/}
      <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet"></link>
      <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
    </Helmet>
  )
}

export default Head

/*
import { StaticQuery } from 'gatsby'
const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
    <StaticQuery
      query={detailsQuery}
      render={(data: ISiteDataProps): JSX.Element => {
        const metaKeywords = keywords || siteKeywords || data.site.siteMetadata.siteKeywords
        const metaDescription = description || siteDescription || data.site.siteMetadata.description

        return (
*/
